




import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { EditorContent, Editor } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  Table,
  TableHeader,
  TableCell,
  TableRow
} from 'tiptap-extensions'

@Component({
  components: {
    Table,
    TableHeader,
    TableCell,
    TableRow,
    EditorContent
  }
})
export default class RichTextDisplay extends Vue {
  @Prop({ type: String }) text!: string
  textEditor = new Editor({
    extensions: [
      new Blockquote(),
      new BulletList(),
      new CodeBlock(),
      new HardBreak(),
      new Heading({ levels: [1, 2, 3] }),
      new HorizontalRule(),
      new ListItem(),
      new OrderedList(),
      new TodoItem(),
      new TodoList(),
      new Link(),
      new Bold(),
      new Code(),
      new Italic(),
      new Strike(),
      new Underline(),
      new Table({
        resizable: true
      }),
      new TableHeader(),
      new TableCell(),
      new TableRow()
    ],
    editable: false,
    content: ''
  })

  mounted () {
    this.textEditor.setContent(this.text)
  }

  @Watch('text', { immediate: true })
  onTextChange () {
    this.textEditor.setContent(this.text)
  }
}
