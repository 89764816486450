











import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import RichTextDisplay from '@/partials/components/RichTextDisplay.vue'
import CenteredThin from '@/layouts/Panels/CenteredThin30-50.vue'

@Component({
  components: {
    CenteredThin,
    RichTextDisplay
  }
})
export default class PatientInstruction extends Vue {
  instruction = {
    title: '',
    body: ''
  }

  created () {
    axios.get(`/public/patient/guideline/${this.$route.params.id}`)
      .then(response => {
        this.instruction = camelCaseKeys(response.data.data, { deep: true })
      })
  }
}
